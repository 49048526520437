<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>记录查询</el-breadcrumb-item>
      <el-breadcrumb-item>开票记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 查询 -->
      <div style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        ">
        <label style="margin: 10px" for="">停车场：
          <el-select v-model="query.pk_id" filterable placeholder="请选择" style="width: 200px" size="small">
            <el-option v-for="item in PkInfoSelect" :key="item.id" :label="item.pkName" :value="item.id">
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px 50px" for="">车牌：
          <el-input style="width: 200px" size="small" placeholder="请输入车牌号" v-model.trim="query.plateNo"
            clearable /></label>
        <label style="margin: 10px" for="">开票抬头：
          <el-input style="width: 200px" size="small" placeholder="请输入开票抬头" v-model.trim="query.invoiceTitle" clearable />
        </label>
        <label style="margin: 10px" for="">开票手机号：
          <el-input style="width: 200px" type="tel" size="small" placeholder="请输入开票手机号" v-model.trim="query.companyPhone"
            clearable />
        </label>
        <label style="margin: 10px" for="">开票状态：
          <el-select v-model="query.invoiceStatus" filterable placeholder="请选择" style="width: 200px" size="small">
            <el-option v-for="item in invoiceStatus" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px 50px" for="">订单号：
          <el-input style="width: 200px" size="small" placeholder="请输入订单号" v-model="query.orderNo" clearable />
        </label>
        <label style="margin: 10px" for="">开票时间：<el-date-picker v-model="query.invoiceTimeStart"
            @change="invoiceTimechange" type="datetime" value-format="timestamp" size="small" placeholder="选择日期时间">
          </el-date-picker>
          至
          <el-date-picker v-model="query.invoiceTimeEnd" @change="outInvoiceTimechange" type="datetime"
            value-format="timestamp" size="small" default-time="23:59:59" placeholder="选择日期时间">
          </el-date-picker>
        </label>
        <label style="margin: 10px" for="">订单时间：<el-date-picker v-model="query.orderTimeStart" @change="orderTimechange"
            type="datetime" value-format="timestamp" size="small" placeholder="选择日期时间">
          </el-date-picker>
          至
          <el-date-picker v-model="query.orderTimeEnd" @change="outOrderTimechange" type="datetime"
            value-format="timestamp" size="small" default-time="23:59:59" placeholder="选择日期时间">
          </el-date-picker>
        </label>
        <el-button style="margin-left: 10px" type="primary" size="small" @click="searchInvoiceRecord">查询</el-button>
        <el-button size="small" @click="reset">重置</el-button>
      </div>
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table ref="multipleTable" :data="tableData" @sort-change="sortchange" stripe tooltip-effect="dark"
          style="width: 100%">
          <el-table-column header-align="left" align="left" prop="orderId" label="编号" width="60"></el-table-column>
          <el-table-column header-align="left" align="left" prop="invoiceTitle" label="开票抬头"
            width="150"></el-table-column>
          <el-table-column header-align="left" align="left" prop="taxnum" label="税号" width="100"></el-table-column>
          <el-table-column header-align="left" align="left" prop="companyPhone" label="开票手机号"
            width="110"></el-table-column>
          <el-table-column header-align="left" align="left" prop="email" label="开票邮箱" width="100"></el-table-column>
          <el-table-column header-align="left" align="left" prop="orderNo" label="订单号" width="100"></el-table-column>
          <el-table-column header-align="left" align="left" prop="actuallyPayMoney" label="开票金额"
            width="100"></el-table-column>
          <el-table-column header-align="left" align="left" prop="invoiceStatus" label="开票状态" width="100">
            <template slot-scope="scope">
              {{ scope.row.invoiceStatus == 0 ? "开票中" : scope.row.invoiceStatus == 1 ? "成功" : "失败" }}
            </template>
          </el-table-column>
          <el-table-column header-align="left" align="left" prop="createTime" label="开票时间" width="100">
            <template slot-scope="scope">
              {{ formatDate(scope.row.createTime, "long") }}
            </template>
          </el-table-column>
          <el-table-column header-align="left" align="left" label="操作" width="210" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="showDetail(scope.row)" size="mini">查看详情</el-button>
              <el-button type="text" @click="showOrder(scope.row)" size="mini">查看订单</el-button>
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current"
        :page-sizes="[10, 50, 100]" :page-size="query.size" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>

    <!-- 订单详情 -->
    <el-dialog title="查看详情" :visible.sync="isShowDetail" width="60%" v-el-drag-dialog>
      <div class="txbj">
        <ul title="开票详情">
          <li>{{ "开票抬头: " + (invoiceDetail.invoiceTitle ? invoiceDetail.invoiceTitle : "") }}</li>
          <li>{{ "开票手机号: " + (invoiceDetail.companyPhone ? invoiceDetail.companyPhone : "") }}</li>
          <li>{{ "开票邮箱: " + (invoiceDetail.email ? invoiceDetail.email : "") }}</li>
          <li>{{ "编号: " + (invoiceDetail.orderId ? invoiceDetail.orderId : "") }}</li>
          <li>{{ "订单号: " + (invoiceDetail.orderNo ? invoiceDetail.orderNo : "") }}</li>
          <li>{{ "开票金额: " + (invoiceDetail.actuallyPayMoney ? invoiceDetail.actuallyPayMoney : "") }}</li>
          <li>{{ "开票状态: " + (invoiceDetail.invoiceStatus == 0 ? "开票中" : invoiceDetail.invoiceStatus == 1 ? "成功"
            : "失败") }}</li>
          <li>{{ "开票时间: " + (invoiceDetail.createTime ? formatDate(invoiceDetail.createTime, "long") : "") }}</li>
          <li>{{ "开票返回信息: " + (invoiceDetail.invoiceReturnInfo ? invoiceDetail.invoiceReturnInfo : "") }}</li>
          <li v-if="showReQueryBtn"><el-button type="info" plain @click="reQueryNuoNuoResult" size="mini">重新获取结果</el-button></li>
        </ul>
      </div>
    </el-dialog>

    <!-- 查看订单 -->
    <el-dialog
      title="查看订单详情"
      :visible.sync="isShowOrder"
      width="60%"
      v-el-drag-dialog
    >
      <div class="txbj">
        <ul v-if="queryOrderList">
          <li>
            订单号:
            <span>{{
              queryOrderList.orderNo ? queryOrderList.orderNo : ''
            }}</span>
          </li>
          <li>
            停车流水号:
            <span>{{ queryOrderList.id ? queryOrderList.id : '' }}</span>
          </li>
          <li v-show="queryOrderList.payStatus === 0">支付状态: 未支付</li>
          <li v-show="queryOrderList.payStatus === 1">支付状态: 支付成功</li>
          <li v-show="queryOrderList.payStatus === 2">支付状态: 支付失败</li>
          <li v-show="queryOrderList.payStatus === 3">支付状态: 部分支付</li>
          <li>
            进场时间:
            {{
              queryOrderList.pkInoutDataDto.intime
                ? conversionTime(queryOrderList.pkInoutDataDto.intime)
                : ''
            }}
          </li>
          <li>
            离场时间:
            {{
              queryOrderList.pkInoutDataDto.outTime
                ? conversionTime(queryOrderList.pkInoutDataDto.outTime)
                : ''
            }}
          </li>
          <li>
            停车时长:
            {{
              queryOrderList.pkInoutDataDto.intime &&
              queryOrderList.pkInoutDataDto.outTime
                ? timeFn(
                    queryOrderList.pkInoutDataDto.intime,
                    queryOrderList.pkInoutDataDto.outTime
                  )
                : ''
            }}
          </li>
          <li>总金额: {{ queryOrderList.orderTotalMoney }}</li>
          <li>优惠金额: {{ queryOrderList.couponMoney }}</li>
          <li>已付金额: {{ queryOrderList.actuallyPayMoney }}</li>
        </ul>
      </div>
      <el-table
        :data="queryOrderLists"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="detailId" label="编号"></el-table-column>
        <el-table-column prop="regionName" label="停车区域"></el-table-column>
        <el-table-column prop="inDate" label="进场时间" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.inDate">{{
              conversionTime(scope.row.inDate)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="outDate" label="出场时间" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.outDate">{{
              conversionTime(scope.row.outDate)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pkDate" label="停车时长" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.outDate">{{
              timeFn(scope.row.inDate, scope.row.outDate)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderMoney" label="停车金额"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>

import { sysDictData } from '@/api/dictionaries.js'
import { findPkInfoSelect, reQueryResult } from '@/api/Parkinglot.js'
import { invoiceRecord, selectOrderInfotk } from '@/api/record.js'
export default {
  data() {
    return {
      isShowParking: false,
      isShowPayment: false,
      isShowOrder: false,
      isShowDetail: false,
      tableData: [],
      query: {
        // 查询条件
        current: 1,
        size: 10,
        pk_id: undefined,
        plateNo: undefined, // 车牌号
        invoiceTimeStart: undefined, // 开票开始时间
        invoiceTimeEnd: undefined, // 开票结束时间
        orderTimeStart: undefined, // 订单开始时间
        orderTimeEnd: undefined, // 订单结束时间
        companyPhone: undefined, //手机号
        invoiceStatus: undefined,
        sortField: 'order_time', // 订单时间order_time  订单金额order_total_money
        sortType: 'desc', // 排序类型 默认desc  asc升序 desc降序
      },
      total: undefined,
      PkInfoSelect: [], //停车场列表
      queryOrderList: undefined, // 保存停车记录弹框数据
      queryOrderLists: undefined, // 保存停车记录弹框数据
      invoiceStatus: [
        {
          label: "开票中",
          value: "0"
        },
        {
          label: "成功",
          value: "1"
        },
        {
          label: "失败",
          value: "2"
        }
      ], //开票状态列表
      invoiceDetail: {},//开票详情
      orderDetail: {},//订单详情
      showReQueryBtn: false,//显示重新查询按钮
    }
  },
  created() {
    this.findPkInfoSelect();
  },
  methods: {
    findQuery(code, list) {
      // 翻译
      const item = list.find((item) => item.code == code)
      return item ? item.name : ''
    },
    //开票时间变更
    invoiceTimechange() {
      this.query.invoiceTimeEnd = this.query.invoiceTimeStart + 3600 * 1000 * 24 * 7 - 1000
    },
    //开票时间变更
    outInvoiceTimechange() {
      if (this.query.invoiceTimeStart === null || this.query.invoiceTimeStart === undefined) {
        this.query.invoiceTimeEnd = null
        return this.$message.warning('请选择开始时间')
      } else if (this.query.invoiceTimeEnd < this.query.invoiceTimeStart) {
        this.query.invoiceTimeEnd = this.query.invoiceTimeStart + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      } else if (this.query.invoiceTimeEnd - this.query.invoiceTimeStart > 3600 * 1000 * 24 * 7) {
        this.query.invoiceTimeEnd = this.query.invoiceTimeStart + 3600 * 1000 * 24 * 7 - 1000
        return this.$message.warning('不可选择比开始时间超过七天以外的日期')
      }
    },
    //订单时间变更
    orderTimechange() {
      this.query.orderTimeEnd = this.query.orderTimeStart + 3600 * 1000 * 24 * 7 - 1000
    },
    //订单时间变更
    outOrderTimechange() {
      if (this.query.orderTimeStart === null || this.query.orderTimeStart === undefined) {
        this.query.orderTimeEnd = null
        return this.$message.warning('请选择开始时间')
      } else if (this.query.orderTimeEnd < this.query.orderTimeStart) {
        this.query.orderTimeEnd = this.query.orderTimeStart + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      } else if (this.query.orderTimeEnd - this.query.orderTimeStart > 3600 * 1000 * 24 * 7) {
        this.query.orderTimeEnd = this.query.orderTimeStart + 3600 * 1000 * 24 * 7 - 1000
        return this.$message.warning('不可选择比开始时间超过七天以外的日期')
      }
    },
    sortchange(column, prop, order) {
      if (column.prop == 'orderTime') {
        this.query.sortField = 'order_time'
      } else if (column.prop == 'orderTotalMoney') {
        this.query.sortField = 'order_total_money'
      }
      if (column.order == 'descending') {
        this.query.sortType = 'desc'
      } else if (column.order == 'ascending') {
        this.query.sortType = 'asc'
      }
    },
    // 计算两个时间差 dateBegin 开始时间
    timeFn(dateBegin, dateEnd) {
      //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
      var dateDiff = dateEnd * 1000 - dateBegin * 1000 //时间差的毫秒数时间戳
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)) //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)) //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)) //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000)
      var leave4 = leave3 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var minseconds = Math.round(leave4 / 1000)
      // var timeFn = "耗时："+dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒"+minseconds+"毫秒";
      var timeFn =
        dayDiff + '天 ' + hours + '小时 ' + minutes + ' 分钟' + seconds + ' 秒'
      return timeFn
    },
    formatDate(timeStamp, type) {
      var date = new Date(timeStamp * 1000)
      const Y = date.getFullYear() + '-'
      const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
      const D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
      const h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
      const m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
      const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      if(type && type == 'short'){
        return Y + M + D
      } else if (type && type == 'long') {
        return Y + M + D + h + m + s
      }
    },
    sysDictData() {
      // 字典
      // sysDictData({ typeId: 86 }).then((res)->())
    },
    reset() {
      // 重置
      this.query = {
        pk_id: this.PkInfoSelect[0].id,
        plateNo: undefined, // 车牌号
        invoiceTimeStart: undefined, // 开票开始时间
        invoiceTimeEnd: undefined, // 开票结束时间
        orderTimeStart: undefined, // 订单开始时间
        orderTimeEnd: undefined, // 订单结束时间
        invoiceStatus: undefined,
        current: 1,
        size: 10,
        sortField: 'order_time', // 订单时间order_time  订单金额order_total_money
        sortType: 'desc'
      }
      this.searchInvoiceRecord()
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          this.query.pk_id = this.PkInfoSelect[0].id
          this.searchInvoiceRecord()
          this.sysDictData()
        }
      })
    },
    searchInvoiceRecord() {
      invoiceRecord(this.query).then(res => {
        if (res.data.code === '200') {
          this.total = res.data.total;
          this.tableData = res.data.data.list;
        }
      })
    },
    showDetail(item) {
      this.isShowDetail = true;
      this.invoiceDetail = item;
      //目前只支持诺诺网 invoiceProvider == 1
      if(item.invoiceProvider == 1){
        let returnInfo = JSON.parse(item.invoiceReturnInfo)
        if(returnInfo != '0000'){
          this.showReQueryBtn = true
        }
      }
    },
    showOrder(item) {
      // this.isShowOrder = true;
      // this.invoiceDetail = item;
      selectOrderInfotk({ id: item.orderId }, { pk_id: this.query.pk_id }).then(res => {
        if (res.data.code === '200') {
          this.queryOrderList = res.data.data
          this.queryOrderLists = res.data.data.orderDetailDtos
          this.isShowOrder = true
        }
      })
    },
    reQueryNuoNuoResult(){
      //目前只支持诺诺网 invoiceProvider == 1
      reQueryResult({ orderId: this.invoiceDetail.orderId }, { pk_id: this.query.pk_id }).then(res=>{
        if(res.data.code == '200'){
          this.$message.success("刷新成功");
          this.invoiceDetail.invoiceReturnInfo = res.data.data.invoiceReturnInfo;
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.query.size = val
      this.searchInvoiceRecord()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.query.current = val
      this.searchInvoiceRecord()
    },
  },
}
</script>
<style lang="scss" scoped>
.txbj {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    li {
      width: 33.33333%;
      line-height: 40px;
      font-weight: 600;

      span {
        color: #409eff;
      }

      img {
        vertical-align: text-top;
      }
    }
  }
}

::v-deep .el-dialog__body {
  padding-top: 0px;
}
</style>
